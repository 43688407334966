import './App.css'
import { Route, Routes } from 'react-router-dom'
import Portal from './pages/Portal'
import Confirmation from './pages/Confirmation'
import PageNotFound from './pages/404_page';
import React from 'react'

function App() {
  const [isVerified, setIsVerified] = React.useState(null);
  const [error, setErrorValue] = React.useState(null);
  return (
    <div className="bg-gray-50 min-h-screen">
      <Routes>
        {/* Portal */}
        <Route path="/" element={<Portal isVerified={isVerified} error={error} />} />
        <Route path="/:company" element={<Portal isVerified={isVerified} error={error} />} />
        <Route path="/:company/:code" element={<Portal isVerified={isVerified} error={error} />} />
        {/* Verification */}
        <Route path="/confirm/:id" element={<Confirmation onFailure={() => setIsVerified(false)} setError={(err) => setErrorValue(err)} />} />
        <Route path="/confirm/:id/:company" element={<Confirmation onFailure={() => setIsVerified(false)} setError={(err) => setErrorValue(err)} />} />
        <Route path="/confirm/:id/:company/:code" element={<Confirmation onFailure={() => setIsVerified(false)} setError={(err) => setErrorValue(err)} />} />
        <Route path="/confirm/:id/:company/:code/:error" element={<Confirmation onFailure={() => setIsVerified(false)} setError={(err) => setErrorValue(err)} />} />
        {/* 404 Page */}
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      <div className="my-6 text-center">
        <p className="text-sm font-medium text-gray-900">VODIUM, LLC. © 2021 - {(new Date().getFullYear())}. All
          rights reserved.</p>
      </div>
    </div>
  )
}

export default App