import { API_ORIGIN, HTTP_METHODS } from "../Utils/constant";
import HttpService from "./http.service";

class OnboardingService {

  static initiateOnboarding = async (body) => {
    try {
      const endpoint = API_ORIGIN + 'onboarding';

      const response = await HttpService.sendRequest(
        endpoint,
        HTTP_METHODS.POST,
        body
      );

      if(response) {
        return true;
      } else {
        throw new Error("Invalid Server Response")
      }

    } catch (error) {
      throw error;
    }
  }

  static getOnboardings = async (token) => {
    try {
      const endpoint = API_ORIGIN + 'onboarding/confirm/' + token;

      const response = await HttpService.sendRequest(
        endpoint,
        HTTP_METHODS.GET,
      );

      const status = await response.json();

      return status;
    } catch (error) {
      throw error;
    }
  }
}

export default OnboardingService;