import React from 'react'
import { useNavigate } from 'react-router-dom'

const PageNotFound = () => {
  const navigate = useNavigate()

  React.useEffect(() => {
    navigate('/');
    // eslint-disable-next-line
  }, [])
}


export default PageNotFound;