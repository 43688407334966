import React, { useEffect, useState } from "react";
import EnterpriseService from "../services/enterprise.service";
import '../pages/styles.css';

const Header = ({ company, code, isVerified }) => {

  const [hasBranding, setHasBranding] = useState(false);
  const [brandingImage, setBrandingImage] = useState("");
  const [brandingText, setBrandingText] = useState("");
  const [text, setText] = useState("");
  const typingProp = { isTyping: false, word: "ENTERPRISES", typingDelay: 200, erasingDelay: 70, newTextDelay: 2000, charIndex: 0 };

  const typingEffect = () => {
    if (typingProp.charIndex < typingProp.word.length) {
      setText((prevText) => prevText + typingProp.word.charAt(typingProp.charIndex));
      setTimeout(() => { typingProp.charIndex++; typingEffect() }, typingProp.typingDelay);
    } else {
      setTimeout(() => { erasingEffect() }, typingProp.newTextDelay);
    }
  };

  const erasingEffect = () => {
    if (typingProp.charIndex > 0) {
      setText((prevText) => prevText.slice(0, -1));
      setTimeout(() => { typingProp.charIndex--; erasingEffect() }, typingProp.erasingDelay);
    } else {
      setTimeout(() => { typingEffect() }, 0);
    }
  };

  useEffect(() => {
    if (!typingProp.isTyping) {
      typingProp.isTyping = true;
      // trigger typing effect
      // setTimeout(() => { typingEffect() }, 800);
    }
    if (company != null && code != null) {
      EnterpriseService.getEnterpriseDetails(company, code).then(res => {

        if ((res.brandingImage !== null && res.brandingImage !== "") || (res.brandingText !== null && res.brandingText !== "")) {
          setHasBranding(true);
        }

        setBrandingImage(res.brandingImage);
        setBrandingText(res.brandingText);
      }).catch(err => {
        console.log(err);
      });
    }
  }, []);

  return (
    <section className="first-section">
      <div className="row" >
        <div className="column-2"></div>
        <div className="column-1 vodium-label" >
          <p className="vodium-label-title">The VODIUM</p>
          <p className="vodium-label-title">is Now Yours</p>
          {/* <p className="vodium-label-typewriter typewriter-animation">{text}</p> */}
          {isVerified !== true &&
            <p className="text-label">Please enter in your first and last name, job title, and company email address to request a VODIUM activation key.</p>
          }
        </div>
        <div hidden={!hasBranding} className="column-separator" ></div>
        <div className="column-1 vodium-label" >
          <center>
            <img className="branding-logo" src={brandingImage} />
          </center>
          <p className="branding-text">{brandingText}</p>
        </div>
        <div className="column-2"></div>
      </div>
    </section>
  )
}

export default Header