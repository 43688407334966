import { HTTP_METHODS } from "../Utils/constant";

class HttpService {

  static setHeaders = () => {
    try {
      const headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Content-Type', 'application/json');
      return headers;
    } catch (error) {
      return new Headers();
    }
  };

  static sendRequest = async (
    url,
    method,
    body = null
  ) => {
    const headers = this.setHeaders();
    let options = {
      method,
      headers,
      body
    };

    if (
      body &&
      (method === HTTP_METHODS.PATCH || method === HTTP_METHODS.POST)
    ) {
      if (typeof body === 'string') {
        options.body = body;
      } else {
        options.body = JSON.stringify(body);
      }
    }

    try {
      const response = await fetch(url, options);

      if (!response.ok) {
        const error = await response.json();
        throw error;
      }
      return response;
    } catch (error) {
      throw error;
    }
  };
}

export default HttpService;