import React from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import './styles.css';
import Header from '../components/Header';

const Confirmation = ({ onFailure, setError }) => {
  const { company, code, error } = useParams();
  const navigate = useNavigate();
  const [isVerified, setIsVerified] = React.useState(false);
  const { id } = useParams();

  const checkValidValue = (value) => {
    // This is to check if the value is an error from the backend
    // If the value is an error key then it should not be set as company or code
    if (value === undefined || value === "INACTIVE_ENTERPRISE" || value === "TOKEN_CONFIRMED" || value === "INVALID_TOKEN") {
      return null;
    } else {
      return value;
    }
  };

  const checkErrorValue = (value) => {
    // This is to check if the value is an error from the backend
    // If the value is an error key then it should not be set as company or code
    if (value === "INACTIVE_ENTERPRISE" || value === "TOKEN_CONFIRMED" || value === "INVALID_TOKEN") {
      console.log("jj", value)
      setError(value);
    }
  };

  React.useEffect(() => {
    var nav = '/';
    if (id) {
      if (id === "success") {
        setIsVerified(true);
      } else if (id === "fail") {
        onFailure();

        checkErrorValue(company);
        checkErrorValue(code);
        checkErrorValue(error);

        var companyValue = checkValidValue(company);
        var codeValue = checkValidValue(code);
        nav = companyValue === null ? nav : nav + companyValue;
        nav = codeValue === null ? nav : nav + '/' + codeValue;
        navigate(nav);
      }
    } else {
      navigate(nav);
    }
    // eslint-disable-next-line
  }, [])

  return (
    <div>
      <Header company={company} code={code} isVerified={isVerified}></Header>
      <section className="second-section">
        <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
          {
            isVerified === true &&
            <div className="bg-green-100 rounded transition-all duration-500 mb-4 mt-4 text-left">
              <div className="p-3">
                <div className="flex items-start justify-center">
                  <svg className="flex-shrink-0 w-5 h-5 text-green-500" xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20" fill="currentColor">
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <p className="ml-3 text-sm font-medium text-green-900">
                    Verification was successful and your activation key will be emailed to you shortly.
                  </p>
                </div>
              </div>
            </div>
          }
          {
            isVerified === true &&
            <div className="grid grid-cols-1 mx-auto mt-12 gap-y-4 lg:grid-cols-8 lg:gap-x-20 lg:mt-20">
              <div className="lg:col-span-3">
                <h1 className="text-3xl font-bold text-indigo-600">Thank you for choosing VODIUM!</h1>
                <p className="mt-4 font-bold">Click below to download the latest version for macOS or Windows.</p>
                <p className="my-4">
                  We have sent you an email with your unique Activation Key. If you've already downloaded our product for a
                  Free Trial and it's lapsed, simply enter the key when prompted.
                </p>

                <div className="flex justify-left">
                  <a href="https://vodium.us/download/windows" className="inline-flex items-center justify-center w-[303px]
                px-6 py-3 mr-3 text-sm font-semibold leading-5 text-white transition-all duration-200 bg-indigo-600 border
                border-transparent rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600
                hover:bg-indigo-500">
                    Download for <br />Windows
                  </a>
                  <a href="https://vodium.us/download/mac" className="inline-flex items-center justify-center w-[303px]
                px-6 py-3 text-sm font-semibold leading-5 text-white transition-all duration-200 bg-indigo-600 border
                border-transparent rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600
                hover:bg-indigo-500">
                    Download for <br />macOS
                  </a>
                </div>
              </div>

              <div className="lg:col-span-5">
                <div className="grid grid-cols-1 mx-auto mt-8 gap-y-4 text-left md:grid-cols-2 md:gap-x-8 lg:mt-0">
                  <div className="overflow-hidden bg-white shadow-md rounded-xl">
                    <div className="p-9">
                      <svg width="38" height="31" viewBox="0 0 38 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M19 3.50556V29.5056M19 3.50556C16.6642 1.95373 13.493 1 10 1C6.50702 1 3.33579 1.95373 1 3.50556V29.5056C3.33579 27.9537 6.50702 27 10 27C13.493 27 16.6642 27.9537 19 29.5056M19 3.50556C21.3358 1.95373 24.507 1 28 1C31.493 1 34.6642 1.95373 37 3.50556V29.5056C34.6642 27.9537 31.493 27 28 27C24.507 27 21.3358 27.9537 19 29.5056"
                          stroke="#111827" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>

                      <h3 className="mt-8 text-xl font-bold text-gray-900 sm:mt-10 font-pj">
                        User guide
                      </h3>
                      <p className="mt-6 text-base text-gray-600 font-pj">
                        Need a hand getting started with VODIUM? Our extensive user guide contains everything you need to
                        know
                        to begin.
                      </p>
                      <a href="https://vodium.us/user-guide" title=""
                        className="inline-flex items-center mt-8 font-bold text-indigo-600 hover:text-indigo-800 font-pj">
                        View user guide
                        <svg className="w-5 h-5 ml-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                          fill="currentColor">
                          <path fillRule="evenodd"
                            d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                            clipRule="evenodd" />
                        </svg>
                      </a>
                    </div>
                  </div>

                  <div className="overflow-hidden bg-white shadow-md rounded-xl">
                    <div className="p-9">
                      <svg width="38" height="26" viewBox="0 0 38 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M25 9L34.1056 4.44721C35.4354 3.78231 37 4.7493 37 6.23607V19.7639C37 21.2507 35.4354 22.2177 34.1056 21.5528L25 17M5 25H21C23.2091 25 25 23.2091 25 21V5C25 2.79086 23.2091 1 21 1H5C2.79086 1 1 2.79086 1 5V21C1 23.2091 2.79086 25 5 25Z"
                          stroke="#111827" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>

                      <h3 className="mt-8 text-xl font-bold text-gray-900 sm:mt-10 font-pj">
                        How to use
                      </h3>
                      <p className="mt-6 text-base text-gray-600 font-pj">
                        Need tips and tricks on how to use VODIUM? Watch our 90 second tutorial video on how to use VODIUM
                        to
                        look like a pro on camera.
                      </p>
                      <a href="https://youtu.be/rWynEatHfGg" title=""
                        className="inline-flex items-center mt-8 font-bold text-indigo-600 hover:text-indigo-800 font-pj">
                        View video tutorial
                        <svg className="w-5 h-5 ml-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                          fill="currentColor">
                          <path fillRule="evenodd"
                            d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                            clipRule="evenodd" />
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
      </section>
    </div>
  )
}

export default Confirmation